import React, { useEffect, useState, useRef } from "react";
import "./InvoiceManager.css";
import DataTable from "../../components/common/DataTable/DataTable";
import addIcon from "../../assets/images/Icons/addIcon.png";
import filterIcon from "../../assets/images/Icons/filter.png";
import searchIcon from "../../assets/images/Icons/search.png";
import calenderIcon from "../../assets/images/Icons/calender.png";
import rightIcon from "../../assets/images/Icons/rightIcon.png";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import { ReviewStatus, ProcessingStatus } from "../../enums/statusEnums";
import { InvoiceTypes } from "../../enums/invoiceTypes";

import {
  useGetInvoicesQuery,
  useDeleteInvoiceMutation,
  useCreateInvoiceMutation,
  useGetInvoiceInsightsQuery,
  useAssignInvoiceMutation,
} from "./invoiceSlice";
import { useGetUsersQuery } from "../Users/UsersSlice";
import PieChart from "../../components/common/PieChart/PieChart";
import FilterModal from "../../components/common/FilterModal/FilterModal";
import { nanoid } from "@reduxjs/toolkit";
import { useNavigate, useSearchParams } from "react-router-dom";
import PDFViewer from "../../components/PDFViewer/PDFViewer";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import AlertModal from "../../components/common/AlertModal/AlertModal";
import toast, { Toaster } from "react-hot-toast";
import EditInvoice from "../../components/InvoiceManager/EditInvoice/EditInvoice";
import InvoiceAssigneeModal from "../../components/InvoiceAssigneeModal/InvoiceAssigneeModal";
import TableSearch from "../../components/TableSearch/TableSearch";
import { useErrorToast } from "../../hooks/useErrorToast";
import InvoiceInsights from "../../components/common/InvoiceInsights/InvoiceInsights";
import { useGetUserHasPermission } from "../../hooks/useGetUserHasPermission";
import { userPermissions } from "../../enums/userPermissons";

const InvoiceManager = () => {
  const [searchParams] = useSearchParams();
  const [invoices, setInvoices] = useState([]);
  const [columns, setColumns] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(
    Number(searchParams.get("page")) || 1
  );
  const [pageSize, setPageSize] = useState(16);
  const [query, setQuery] = useState("");
  const [filterObj, setFilterObj] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [insights, setInsights] = useState("");
  const storedFilters = localStorage.getItem("appliedFilters");
  const showErrorToast = useErrorToast();
  const getUserHasPermission = useGetUserHasPermission();

  const initialFilters = storedFilters ? JSON.parse(storedFilters) : null;

  const [appliedFilters, setAppliedFilters] = useState(initialFilters);

  const [openAddInvoice, setOpenAddInvoice] = useState(false);

  const [invoiceGuid, setInvoiceGuid] = useState("");
  const [assignToModalIsOpen, setAssignToModalIsOpen] = useState(false);

  const handleOpenAddInvoice = () => {
    setOpenAddInvoice((prev) => !prev);
  };

  const toggelAssignToModal = () => {
    setAssignToModalIsOpen((prev) => !prev);
  };
  const navigate = useNavigate();

  const {
    data: invoiceData,
    isLoading: isInvoiceDataLoading,
    isFetching: isInvoiceDataFetching,
    isSuccess: isInvoiceDataSuccess,
    isError: isInvoiceDataError,
    error,
  } = useGetInvoicesQuery({
    Status: localStorage.getItem("filterObj") || filterObj,
    Query: query,
    Page: pageNumber,
    PageSize: pageSize,
  });

  const [
    createInvoice,
    {
      isLoading: createInvoiceIsLoading,
      isSuccess: createInvoiceIsSuccess,
      isError: createInvoiceIsError,
    },
  ] = useCreateInvoiceMutation();

  const [deleteInvoice, { isLoading, isSuccess, isError }] =
    useDeleteInvoiceMutation();

  let gridRef = useRef(null);

  const handleSearch = (e) => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    }
    setQuery(e.target.value);
  };

  const showInvoice = (id) => {
    setInvoiceId(id);
    setIsOpen(true);
  };

  const handleDeleteInvoice = (id) => {
    setShowAlert(true);
    setInvoiceId(id);
  };

  const handleConfirmation = async (state) => {
    setShowAlert((prev) => !prev);
    if (state) {
      try {
        const response = await deleteInvoice(invoiceId);
        toast.success("Invoice Deleted");
      } catch (error) {
        showErrorToast(error);
      }
    }
  };

  const generateColumns = (data) => {
    if (data.length <= 0) {
      return;
    }
    const optionsConfig = {
      key: nanoid(),
      field: "options",
      headerText: "Options",
      template: (props) => (
        <div>
          <button
            className="btn me-1 p-0 px-1 assignTo-btn"
            onClick={() => {
              toggelAssignToModal();
              setInvoiceGuid(props.id);
            }}
          >
            Assign To
          </button>
          <button
            className="btn custom-icon-details me-1"
            onClick={() => navigate(`/invoice/view/${props["id"]}`)}
          ></button>
          {getUserHasPermission(userPermissions.UPDATE_INVOICE) && (
            <button
              className="btn custom-icon-edit me-1"
              onClick={() => navigate(`/invoice/edit/${props["id"]}`)}
              disabled={
                props.reviewStatusId === ReviewStatus.APPROVED ||
                props.reviewStatusId === ReviewStatus.AI_APPROVED ||
                props.processingStatusId ===
                  ProcessingStatus.READY_FOR_PROCESSING ||
                props.processingStatusId === ProcessingStatus.SUCCESSFUL
              }
            ></button>
          )}
          {getUserHasPermission(userPermissions.DELETE_INVOICE) && (
            <button
              className="btn custom-icon-delete me-1"
              onClick={() => handleDeleteInvoice(props["id"])}
            ></button>
          )}
        </div>
      ),
      width: "205px",
    };

    const columns = Object.keys(data[0])
      .map((field) => {
        if (field === "id") {
          return null;
        }
        if (field === "vatNumber") {
          return null;
        }
        if (field === "reviewStatusId") {
          return null;
        }
        if (field === "processingStatusId") {
          return null;
        }
        if (field === "netAmount") {
          return null;
        }

        if (field === "pageSettings") {
          return null;
        }

        if (field === "typeId") {
          return null;
        }

        let columnConfig = {
          key: nanoid(),
          field,
          headerText: field
            .replace(/([A-Z])/g, " $1")
            .trim()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
          alignment: "left",
          template: null,
          width: "150px",
        };

        if (field === "invoiceNumber") {
          columnConfig.width = "160px";
        }
        if (field === "client") {
          columnConfig.headerText = "Supplier";
        }
        if (field === "vatAmount") {
          columnConfig.headerText = "VAT";
          columnConfig.width = "120px";
          columnConfig.alignment = "right";
        }
        if (field === "totalAmount") {
          columnConfig.headerText = "Total";
          columnConfig.width = "120px";
          columnConfig.alignment = "right";
        }
        if (field === "orderNumber") {
          columnConfig.width = "100px";
          columnConfig.headerText = "PO Number";
        }

        if (field === "reviewStatus") {
          columnConfig.template = (props) => {
            let style;
            switch (props.reviewStatusId) {
              case ReviewStatus.NEW:
                style = "new-badge";
                break;
              case ReviewStatus.DATA_EXTRACTED:
                style = "data-extracted-badge";
                break;
              case ReviewStatus.AI_APPROVED:
                style = "ai-approved-badge";
                break;
              case ReviewStatus.REVIEW_REQUIRED:
                style = "review-required-badge";
                break;
              case ReviewStatus.REVIEWED:
                style = "reviewed-badge";
                break;
              case ReviewStatus.APPROVAL_REQUIRED:
                style = "approval-required-badge";
                break;
              case ReviewStatus.APPROVED:
                style = "approved-badge";
                break;
              case ReviewStatus.REJECTED:
                style = "rejected-badge";
                break;
              default:
                style = "default-badge";
            }
            return (
              <span className={`status-badge ${style}`}>{props[field]}</span>
            );
          };
          columnConfig.width = "200px";
          columnConfig.alignment = "center";
        }

        if (field === "processingStatus") {
          columnConfig.template = (props) => {
            let style;
            switch (props.processingStatusId) {
              case ProcessingStatus.NEW:
                style = "new-badge";
                break;
              case ProcessingStatus.UNDER_REVIEW:
                style = "under-review-badge";
                break;
              case ProcessingStatus.READY_FOR_PROCESSING:
                style = "ready-for-processing-badge";
                break;
              case ProcessingStatus.PROCESSING:
                style = "processing-badge";
                break;
              case ProcessingStatus.SUCCESSFUL:
                style = "successful-badge";
                break;
              case ProcessingStatus.FAILED:
                style = "failed-badge";
                break;
              case ProcessingStatus.IN_QUERY:
                style = "inquery-badge";
                break;
              default:
                style = "default-badge";
            }
            return (
              <div className="text-center">
                <span className={`status-badge ${style}`}>{props[field]}</span>
              </div>
            );
          };
          columnConfig.width = "180px";
        }

        if (field === "assignedTo") {
          columnConfig.template = (props) => {
            return <span>{props[field]?.name ?? "N/A"}</span>;
          };
          columnConfig.width = "160px";
        }

        if (field === "type") {
          columnConfig.headerText = "";
          columnConfig.template = (props) => {
            let style;
            switch (props.typeId) {
              case InvoiceTypes.INVOICE:
                style = "invoice-badge";
                break;
              case InvoiceTypes.CREDIT_NOTE:
                style = "credit-note-badge";
                break;
              case InvoiceTypes.SAMPLE_INVOICE:
                style = "sample-invoice-badge";
                break;

              default:
                style = "";
            }
            return <div className={`${style}`} title={props[field]}></div>;
          };
          columnConfig.width = "20px";
        }

        return columnConfig;
      })
      .filter(Boolean);
    columns.splice(columns.length, 0, optionsConfig);
    return columns;
  };

  const handlePageChange = (page) => {
    if (page !== undefined || page !== null) {
      setPageNumber(page);
    }
  };

  useEffect(() => {
    if (invoiceData) {
      const data = invoiceData?.ids?.map((id) => invoiceData?.entities[id]);
      setInvoices(data);

      setColumns(generateColumns(data));
    }
    if (isInvoiceDataError) {
      showErrorToast(error);
    }
  }, [invoiceData, isInvoiceDataError]);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const currencyFormatter = (value) => {
    const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    });
    return formatter.format(value);
  };

  const handleFilter = (filterString, appliedFilters) => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    }
    setFilterObj(filterString);
    setModalIsOpen(!modalIsOpen);
    setAppliedFilters(appliedFilters);
    localStorage.setItem("filterObj", filterString);
    localStorage.setItem("appliedFilters", JSON.stringify(appliedFilters));
  };

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedFile && selectedFile.type === "application/pdf") {
        const formData = new FormData();
        formData.append("File", selectedFile);
        formData.append("CategoryGuid", "a75c58df-4a50-4e32-ae59-09253109edb0");
        const response = await createInvoice(formData);
        toast.success("Invoice Uploaded");
      } else {
        toast.error("Please select a PDF file.");
        setSelectedFile(null);
        return;
      }
      handleOpenAddInvoice();
      setSelectedFile(null);
    } catch (error) {
      showErrorToast(error);
      setSelectedFile(null);
    }
  };

  const renderFilterBadge = (label, value, key) => (
    <span key={key} className="badge filter-badge me-1">
      {label} {value}
    </span>
  );

  const resetAssignToAction = () => {
    setInvoiceGuid("");
  };

  return (
    <div className="invoice-manager">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      {isInvoiceDataLoading && <LoadingSpinner />}
      <InvoiceInsights />

      <div className="content">
        <div className="custom-card invoice-container">
          <div className="row">
            <div className="col-12">
              <div className="invoice-table">
                <div className="row justify-content-between">
                  <div className="col-1">
                    <button
                      className="btn btn-add-invoice w-100"
                      onClick={handleOpenAddInvoice}
                    >
                      Add Invoice
                      <img
                        src={addIcon}
                        width={18}
                        alt="add icon"
                        className="ms-2 pb-1"
                      />
                    </button>
                  </div>

                  <div className="col-auto">
                    {appliedFilters && (
                      <>
                        {appliedFilters.reviewStatuses.map((item, index) =>
                          renderFilterBadge(
                            "Review Status =",
                            item.name,
                            `reviewStatus-${index}`
                          )
                        )}
                        {appliedFilters.processStatuses.map((item, index) =>
                          renderFilterBadge(
                            "Processing Status =",
                            item.name,
                            `processingStatus-${index}`
                          )
                        )}
                        {appliedFilters.createdOnGreaterThan &&
                          renderFilterBadge(
                            "Created on after",
                            appliedFilters.createdOnGreaterThan,
                            "createdOnGreaterThan"
                          )}
                        {appliedFilters.createdOnLessThan &&
                          renderFilterBadge(
                            "Created on before",
                            appliedFilters.createdOnLessThan,
                            "createdOnLessThan"
                          )}
                        {appliedFilters.updatedOnLessThan &&
                          renderFilterBadge(
                            "Updated on before",
                            appliedFilters.updatedOnLessThan,
                            "updatedOnLessThan"
                          )}
                        {appliedFilters.updatedOnGreaterThan &&
                          renderFilterBadge(
                            "Updated on after",
                            appliedFilters.updatedOnGreaterThan,
                            "updatedOnGreaterThan"
                          )}
                        {appliedFilters.totalGreaterThan &&
                          renderFilterBadge(
                            "Min Total",
                            currencyFormatter(appliedFilters.totalGreaterThan),
                            "totalGreaterThan"
                          )}
                        {appliedFilters.totalLessThan &&
                          renderFilterBadge(
                            "Max Total",
                            currencyFormatter(appliedFilters.totalLessThan),
                            "totalLessThan"
                          )}
                        {appliedFilters.assignedToId &&
                          renderFilterBadge(
                            "Assigned To = ",
                            appliedFilters.assignedToId.name,
                            "assignedToId"
                          )}
                      </>
                    )}
                  </div>

                  <div className="col-auto">
                    <div className="d-flex align-items-center">
                      <TableSearch
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        setQuery={setQuery}
                        isLoading={isInvoiceDataFetching}
                      />
                      <button className="btn" onClick={toggleModal}>
                        <img src={filterIcon} width={32} alt="" />
                      </button>
                    </div>
                  </div>
                </div>

                <div style={{ width: "100%" }}>
                  {invoices && invoices.length > 0 ? (
                    <DataTable
                      data={invoices}
                      columns={columns}
                      handlePageChange={handlePageChange}
                      currentPage={pageNumber}
                    />
                  ) : (
                    "No invoices available"
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FilterModal
        isOpen={modalIsOpen}
        toggle={toggleModal}
        handleFilter={handleFilter}
      />

      {/* <Modal isOpen={isOpen} toggle={toggle} centered size="xl" fullscreen>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <PDFViewer invoiceId={invoiceId} height={"90vh"} />
          <EditInvoice id={invoiceId} viewOnly={true} />
        </ModalBody>
      </Modal> */}
      <Modal isOpen={openAddInvoice} toggle={handleOpenAddInvoice} centered>
        <ModalHeader toggle={handleOpenAddInvoice}>
          Choose Invoice PDF file
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleFormSubmit}>
            <input
              type="file"
              name="file"
              onChange={handleFileChange}
              required
            />
            <button type="submit" className="btn btn-blue">
              Upload
            </button>
          </form>
          {createInvoiceIsLoading && "Uploading..."}
        </ModalBody>
      </Modal>
      <InvoiceAssigneeModal
        isOpen={assignToModalIsOpen}
        toggleModal={toggelAssignToModal}
        invoiceGuid={invoiceGuid}
        resetAction={resetAssignToAction}
      />
      <AlertModal isOpen={showAlert} toggle={handleConfirmation} />
    </div>
  );
};

export default InvoiceManager;
